import validate from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/home/runner/work/dating-frontend/dating-frontend/src/middleware/redirect-trailing-slash.global.ts";
import redirects_45global from "/home/runner/work/dating-frontend/dating-frontend/src/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "policy-redirects": () => import("/home/runner/work/dating-frontend/dating-frontend/src/middleware/policy-redirects.ts"),
  "sanctum:auth": () => import("/home/runner/work/dating-frontend/dating-frontend/modules/nuxt-auth-sanctum/runtime/middleware/sanctum.auth.ts"),
  "sanctum:guest": () => import("/home/runner/work/dating-frontend/dating-frontend/modules/nuxt-auth-sanctum/runtime/middleware/sanctum.guest.ts")
}