import revive_payload_client_aMvYYvnXH4 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_c9ixRQq54M from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_S7S4Nb2pAW from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_b1HLSTaLNC from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_@types+node@22.7.5_sass@1.79._lor2bmia4wiokwfyhqj6q3hici/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ET1sW0ktV5 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_N5hrGPzNex from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HLj7Va0Oue from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aFTCOL0AaZ from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3hjslkWEoO from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_oWCiCCYhKR from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__lcjbbw5gua62gudwubafkp23eu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0nh0Rm9E37 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_R3BbJeS3Nz from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_jqD0tIk8ea from "/home/runner/work/dating-frontend/dating-frontend/modules/nuxt-auth-sanctum/runtime/plugin.ts";
import http_plugin_nuxt_ujnBCrR642 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.14.0_rollup@3.29.5_sass@1.79.5_typescript@5.5.4_v_6jwfxcv2clgwky6ohwrqaqhp64/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import switch_locale_path_ssr_S8yvyOXUBA from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_7ULPcRj5Re from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Lblh4nTTro from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5_fy7d4343eawmk63ifzuuomqh7i/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_2Jozffge65 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_ztwumCCasp from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import nuxt_plugin_rJuoJXd6KR from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_6f0cgNrnhb from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_t_mtalnngikmxps525emxzimb6h4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_UcB36bLgxX from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_t_mtalnngikmxps525emxzimb6h4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_hC4HdMXuEY from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.2_h3@1.13.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_4qqlpfhryxcubdrno7btn5vjsy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_X7Xa0PyuTe from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.2_h3@1.13.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_4qqlpfhryxcubdrno7btn5vjsy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_Ynsn0D1nAz from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_@types+node@22.7.5_sa_6nroanxrw67g2psxkpjkoxojc4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_OD7HTu9juO from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@5.4.8_@types+node@22.7.5_sa_6nroanxrw67g2psxkpjkoxojc4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import api_routes_Q0W49V3wqt from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/api-routes.ts";
import custom_pwa_register_client_fOvh2D2KfW from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/custom-pwa-register.client.ts";
import dompurify_R8xb7bJPqP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/dompurify.ts";
import floating_1Jsyq3dLZq from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/floating.ts";
import handle_app_color_mn7Bwa8Xck from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/handle-app-color.ts";
import modal_saNsw8xGBA from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/modal.ts";
import pusher_js_client_2TZYA6fBVP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/pusher-js.client.ts";
import select_fkZYP3esEZ from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/select.ts";
import toast_notification_ycBNrvS44W from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/toast-notification.ts";
export default [
  revive_payload_client_aMvYYvnXH4,
  unhead_c9ixRQq54M,
  router_S7S4Nb2pAW,
  _0_siteConfig_b1HLSTaLNC,
  payload_client_ET1sW0ktV5,
  navigation_repaint_client_N5hrGPzNex,
  check_outdated_build_client_HLj7Va0Oue,
  chunk_reload_client_aFTCOL0AaZ,
  plugin_vue3_3hjslkWEoO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oWCiCCYhKR,
  plugin_client_0nh0Rm9E37,
  plugin_client_R3BbJeS3Nz,
  plugin_jqD0tIk8ea,
  http_plugin_nuxt_ujnBCrR642,
  switch_locale_path_ssr_S8yvyOXUBA,
  i18n_7ULPcRj5Re,
  plugin_Lblh4nTTro,
  plugin_client_2Jozffge65,
  plugin_ztwumCCasp,
  nuxt_plugin_rJuoJXd6KR,
  pwa_icons_6f0cgNrnhb,
  pwa_client_UcB36bLgxX,
  titles_hC4HdMXuEY,
  defaults_X7Xa0PyuTe,
  siteConfig_Ynsn0D1nAz,
  inferSeoMetaPlugin_OD7HTu9juO,
  api_routes_Q0W49V3wqt,
  custom_pwa_register_client_fOvh2D2KfW,
  dompurify_R8xb7bJPqP,
  floating_1Jsyq3dLZq,
  handle_app_color_mn7Bwa8Xck,
  modal_saNsw8xGBA,
  pusher_js_client_2TZYA6fBVP,
  select_fkZYP3esEZ,
  toast_notification_ycBNrvS44W
]