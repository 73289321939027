const UserRoleMeet = {
  moderator: 'moderator',
  none: 'none'
} as const;

const EventsMeet = {
  setUserInfo: 'setUserInfo'
} as const;

const TrackTypeMeet = {
  audio: 'audio',
  video: 'video',
  desktop: 'desktop'
} as const;

const ToastMessageMeet = {
  terminated: 'The meeting has been terminated',
  adminKicked: 'JITSI.NOTIFY.ADMIN_KICKED',
  setRoleModerator: 'JITSI.NOTIFY.SET_ROLE_MODERATOR'
};

const CustomMeetEvent = {
  SharedVideo: 'shared-video',
  ToggleHand: 'toggle-hand'
};

const VideoShareStatus = {
  Play: 'play',
  Paused: 'paused',
  Stop: 'stop',
  Ended: 'ended'
} as const;

const MeetMessageReceived = {
  ASK_TO_UNMUTE: 'ASK_TO_UNMUTE'
};

type TrackTypeV = (typeof TrackTypeMeet)[keyof typeof TrackTypeMeet];
type UserRoleMeetV = (typeof UserRoleMeet)[keyof typeof UserRoleMeet];
type VideoShareStatusV = (typeof VideoShareStatus)[keyof typeof VideoShareStatus];
type MeetMessageReceivedV = (typeof MeetMessageReceived)[keyof typeof MeetMessageReceived];

export {
  UserRoleMeet,
  EventsMeet,
  TrackTypeMeet,
  ToastMessageMeet,
  CustomMeetEvent,
  VideoShareStatus,
  MeetMessageReceived,
  type TrackTypeV,
  type UserRoleMeetV,
  type VideoShareStatusV,
  type MeetMessageReceivedV
};
