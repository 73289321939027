import type { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cx } from 'class-variance-authority';
import type { VNodeChild, ComponentInternalInstance } from 'vue';

const cn = (...inputs: ClassValue[]) => twMerge(cx(inputs));

function findChildrenWithProvide(key: InjectionKey<any> | symbol, vnode?: VNodeChild): ComponentInternalInstance[] {
  if (!vnode || typeof vnode !== 'object') return [];

  if (Array.isArray(vnode)) {
    return vnode.map(child => findChildrenWithProvide(key, child)).flat(1);
  }
  if (Array.isArray(vnode.children)) {
    return vnode.children.map(child => findChildrenWithProvide(key, child)).flat(1);
  }
  if (vnode.component) {
    if (vnode.component.subTree) {
      return findChildrenWithProvide(key, vnode.component.subTree).flat(1);
    }
  }

  return [];
}

const removeEmoji = (text?: string) => {
  if (text) {
    return text
      .replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      )
      .replace(/\s+/g, ' ')
      .trim();
  }
  return '';
};

const isOnlyEmojis = (text?: string) => {
  if (!text) {
    return false;
  }
  const div = document.createElement('div');
  div.innerHTML = text;
  const textContent = div.textContent || div.innerText || '';
  div.remove();
  const noSpaces = textContent.replace(/\s+/g, '');
  const emojiRegex = /^\p{Emoji}+$/u;
  return emojiRegex.test(noSpaces);
};

const parseMessageContent = (message: string = '') => {
  if (!message) {
    return '';
  }
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(message, 'text/html');
  return htmlDoc.body.textContent;
};

const getMessagePreviewMeta = (message: Message) => {
  let content = useTruncate(parseMessageContent(message.message) || '', { length: 80 });

  if (message.tenor_url) {
    content = content ? `GIF, ${content}` : 'GIF';
  }

  if (!content && message.attachments?.length) {
    content = useMap(message.attachments, 'name').join(', ');
  }

  return {
    content,
    gif: message.tenor_url
  };
};

const findDoomMessage = (messageId: ResourceId) => document.getElementById(`message-${messageId}`);
const isAbortError = (error: any) => error?.name === 'AbortError';
const getPlayerStatus = (el: HTMLAudioElement | HTMLVideoElement) => {
  if (!el) {
    return null;
  }
  if (el.ended) {
    return VideoShareStatus.Ended;
  }
  if (el.paused) {
    return VideoShareStatus.Paused;
  }

  if (el.seeking || el.readyState < 3) {
    return;
  }
  return VideoShareStatus.Play;
};

const setAudioSinkId = async (deviceId: string, audieEl?: HTMLAudioElement) => {
  if (typeof audieEl?.sinkId !== 'undefined') {
    try {
      await audieEl.setSinkId(deviceId);
      return true;
    } catch (error) {
      console.error('Failed to set audio output device:', error);
      return false;
    }
  }
  return true;
};

const isDocumentFullScreen = () => {
  return Boolean(
    document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
  );
};
const isHoverSupported = () => window.matchMedia('(hover: hover)').matches;

export {
  cn,
  findChildrenWithProvide,
  removeEmoji,
  parseMessageContent,
  getMessagePreviewMeta,
  findDoomMessage,
  isAbortError,
  isOnlyEmojis,
  getPlayerStatus,
  setAudioSinkId,
  isDocumentFullScreen,
  isHoverSupported
};
